import request from '@/plugins/request';

export function goodsInfo (data) {
    return request({
        url: 'users/goods/goods_info',
        method: 'post',
        data
    });
}

export function getGoodsLink(data) {
    return request({
        url: 'users/goods/goods_link',
        method: 'post',
        data
    });
}

export function getGoodsGroupLink(data) {
    return request({
        url: 'users/goods/goods_group',
        method: 'post',
        data
    });
}

//秒杀商品详情
export function goods_info_seckill(data) {
    return request({
        url: 'users/goods/goods_info_seckill',
        method: 'post',
        data
    });
}

//团购商品详情
export function goods_info_gbs(data) {
    return request({
        url: 'users/goods/goods_info_gbs',
        method: 'post',
        data
    });
}


