/**
 * 商品详情
 * */
import { goodsInfo,getGoodsLink } from '@/api/goods';
export default {
    namespaced: true,
    state: {
        isLoading: false, // 是否展示loading动画
        goodsInfo:{
            goods_attr:[],
        },// 商品详情
        goodsLink:{}
    },
    mutations: {
        SET_GOODS_INFO(state, data){
            state.goodsInfo = data;
        },
        SET_GOODS_LINK(state, data){
            state.goodsLink = data;
        },
    },
    actions: {
        loadGoodsInfo ({ commit },data){
            return new Promise((resolve, reject) => {
                goodsInfo(data).then(async res => {
                    commit('SET_GOODS_INFO', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadGoodsLink ({ commit },data){
            return new Promise((resolve, reject) => {
                getGoodsLink(data).then(async res => {
                    commit('SET_GOODS_LINK', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
    },
    getters: {

    }
}
