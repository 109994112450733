import util from "@/libs/util";
import {settled_info} from "@/api/user";

export default {
    namespaced: true,
    state: {
       step:0,
       settled_info:{}
    },
    mutations:{
        // 设置入驻步骤
        SET_STEP (state, step){
            state.step = step;
        },
        SET_SETTLED_INFO(state,step){
            state.settled_info = step;
        }
    },
    actions:{
        loadSettledInfo({ commit }) {
            return new Promise((resolve, reject) => {
                // 开始请求登录接口
                const app_token = util.cookies.get('app_token');
                settled_info({
                    app_token
                })
                    .then(async res => {
                        commit('SET_SETTLED_INFO', res.data);
                        // 结束
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
    }
}