/**
 * 用户信息
 * */
import {userAddressList} from '@/api/user';
import util from "@/libs/util";
import storage from '@/utils/storage';

export default {
    namespaced: true,
    state: {
        isLogin: !!util.cookies.get('app_token'),
        // 用户信息
        userInfo: {
            username: '',
            app_token: '',
            email: '',
            headimg: '',
            mobile_phone: '',
            user_id: 0
        },
        address: {}
    },
    mutations: {
        // 设置收获地址
        SET_USER_ADDRESS(state, data) {
            state.address = data;
        }
    },
    actions: {
        /**
         * @description 设置用户数据
         * @param {Object} state vuex state
         * @param {Object} dispatch vuex dispatch
         * @param {*} info info
         */
        set({state, dispatch}, info) {
            return new Promise(async resolve => {
                // store 赋值
                state.userInfo = info;
                storage.setItem('userInfo', info)
                // 持久化
                await dispatch('front/db/set', {
                    dbName: 'sys',
                    path: 'user.userInfo',
                    value: info,
                    user: true
                }, {root: true});
                // end
                resolve();
            })
        },
        /**
         * @description 从数据库取用户数据
         * @param {Object} state vuex state
         * @param {Object} dispatch vuex dispatch
         */
        load({state, dispatch}) {
            return new Promise(async resolve => {
                // store 赋值
                state.userInfo = await dispatch('front/db/get', {
                    dbName: 'sys',
                    path: 'user.userInfo',
                    defaultValue: {},
                    user: true
                }, {root: true});
                // end
                resolve();
            })
        },
        /**
         * @description 地址列表
         */
        loadAddress({commit}) {
            return new Promise((resolve, reject) => {
                // 开始请求登录接口
                const app_token = util.cookies.get('app_token');
                userAddressList({
                    app_token
                })
                    .then(async res => {
                        if(res.data.length > 0){
                            commit('SET_USER_ADDRESS', res.data);
                        }
                        // 结束
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
    }
}
