<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <!-- 部分路由不应该包含这个Footer -->
    <Footer v-if="excludeRoutes.indexOf($route.name) == -1"></Footer>
  </div>
</template>

<script>
import Header from '@/views/header/Header';
import Footer from '@/views/footer/Footer';
import storage from "@/utils/storage";

export default {
  name: 'App',
  data () {
    return {
      excludeRoutes: ['HomeIndex', 'MyAddress', 'AddAddress', 'MyOrder', 'MyShoppingCart'],

      windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度

    };
  },
  // watch: {
  //   windowHeight (val) {
  //     let that = this;
  //     console.log("实时屏幕高度：",val, that.windowHeight );
  //   },
  //   windowWidth (val) {
  //     let that = this;
  //     console.log("实时屏幕宽度：",val, that.windowHeight );
  //   }
  // },
  components: {
    Header,
    Footer
  },
  mounted() {
    storage.setItem("siteName", '一浪五金机电');
    // var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    // window.onresize = () => {
    //   return (() => {
    //     window.fullHeight = document.documentElement.clientHeight;
    //     window.fullWidth = document.documentElement.clientWidth;
    //     that.windowHeight = window.fullHeight;  // 高
    //     that.windowWidth = window.fullWidth; // 宽
    //   })()
    // };
  },

};
</script>
<style lang="scss">
  #app {
   @include background_color($light_background_color);
    min-width: 1200px;
  }
  body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
</style>
