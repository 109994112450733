import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store/index';
import ViewUI from 'view-design';
import iViewPro from '@/libs/iview-pro/iview-pro.min.js';
import Viewer from 'v-viewer'
import 'view-design/dist/styles/iview.css';
import './libs/iview-pro/iview-pro.css';
import 'vue-happy-scroll/docs/happy-scroll.css'
import * as filters from "./utils/filters";
import vueAwesomeCountdown from 'vue-awesome-countdown'

// import { VueMasonryPlugin } from 'vue-masonry';
// Vue.use(VueMasonryPlugin);

import waterfall from 'vue-waterfall2';

Vue.use(waterfall);

Vue.use(vueAwesomeCountdown, 'vac');

import 'element-ui/lib/theme-chalk/index.css';
import { Carousel,CarouselItem,Image,Empty,Pagination,Input,Icon,Button,Tabs,TabPane} from 'element-ui';

Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Empty);
Vue.use(Image);
Vue.use(Pagination);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Tabs);
Vue.use(TabPane);

const { title } = require("@/config");

Vue.use(ViewUI);
Vue.use(iViewPro);

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key]);
});

// 全局引入封装组件
import { InstallAll } from "@/components/global.js";
Vue.use(InstallAll);

Vue.prototype.Bus = new Vue();

// 懒加载
import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: require('./assets/images/no.png'),
    loading: require('./assets/images/moren.jpg'),
    attempt: 1,
    listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove']
});

// 配置
import Setting from './setting';

import 'viewerjs/dist/viewer.css'

import formCreate from '@form-create/iview'

import modalForm from '@/utils/modalForm'

window.Promise = Promise;
Vue.prototype.$modalForm = modalForm;

Vue.use(formCreate);
// 多语言
import i18n from '@/i18n';

import './styles/index.less';


if (window) window.$t = (key, value) => i18n.t(key, value);
Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
});
Vue.use(ViewUI, {
    i18n: (key, value) => i18n.t(key, value)
});

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
    ViewUI.LoadingBar.start();
    window.document.title = to.meta.title === undefined ? title : to.meta.title;
    next();
});

router.afterEach(route => {
    ViewUI.LoadingBar.finish();
});

Vue.prototype.linkTo = function (url) {
    if (url.substr(0, 1) === "/") {
        // 非外部链接，没有origin，只有路由地址
        if (router.mode === "hash") {
            window.open(location.origin + "/#" + url, "_blank");
        } else {
            window.open(location.origin + url, "_blank");
        }
    } else {
        // 外部链接，完整的url地址
        window.open(url, "_blank");
    }
};

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    async created() {
        // 加载用户登录的数据
        this.$store.dispatch('front/account/load');
    }
}).$mount('#app')
