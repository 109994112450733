/**
 * 商品详情
 * */
import { shoppingCartList } from '@/api/cart';
export default {
    namespaced: true,
    state: {
        shoppingCart: [], // 购物车
        newShoppingCart: [], // 刚加入的购物车，作为展示
    },
    mutations: {
      // 添加购物车
      SET_SHOPPING_CART_LIST (state, data){
            state.shoppingCart = data;
        }

    },
    actions: {
        loadShoppingCartList ({ commit },data){
            return new Promise((resolve, reject) => {
                shoppingCartList(data).then(async res => {
                    let resData = res.data;
                    commit('SET_SHOPPING_CART_LIST', resData);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        }
    },
    getters: {

    }
}
