import request from '@/plugins/request';

export function addShoppingCart(data) {
    return request({
        url: 'users/cart/cart_add',
        method: 'post',
        data
    });
}

export function shoppingCartList(data) {
    return request({
        url: 'users/cart/cart_list',
        method: 'post',
        data
    });
}

export function shoppingCartDel(data) {
    return request({
        url: 'users/cart/cart_del',
        method: 'post',
        data
    });
}

export function cart_edit(data) {
    return request({
        url: 'users/cart/cart_edit',
        method: 'post',
        data
    });
}