import request from '@/plugins/request';
// import adRequest from '@/plugins/adRequest';
/**
 * @description 首頁輪播圖--列表
 */
export function adbannerList () {
    return request({
        url: 'users/index/ad_list',
        method: 'post'
    });
}

export function specialsList (data) {
    return request({
        url: 'users/index/promote_list',
        method: 'post',
        data
    });
}

export function shopsList (data) {
    return request({
        url: 'users/index/supplier_list',
        method: 'post',
        data
    });
}

export function brandList (data) {
    return request({
        url: 'users/index/brand_list',
        method: 'post',
        data
    });
}

export function cateList (data) {
    return request({
        url: 'users/index/cate_list',
        method: 'post',
        data
    });
}

export function xy (data) {
    return request({
        url: 'users/index/xy',
        method: 'post',
        data
    });
}

//获取平台店铺等级
export function supplier_rank (data) {
    return request({
        url: 'users/index/supplier_rank',
        method: 'post',
        data
    });
}

//获取平台店铺分类
export function supplier_type (data) {
    return request({
        url: 'users/index/supplier_type',
        method: 'post',
        data
    });
}

//是否是审核期间 (商家)
export function supplier_audit (data) {
    return request({
        url: 'users/index/supplier_audit',
        method: 'post',
        data
    });
}

//根据平台分类获取商品
export function search_goods(data) {
    return request({
        url: 'users/index/search_goods',
        method: 'post',
        data
    });
}

//首页 更多好货
export function hot_list(data) {
    return request({
        url: 'users/index/hot_list',
        method: 'post',
        data
    });
}

export function dianpu_list(data) {
    return request({
        url: 'users/index/dianpu_list',
        method: 'post',
        data
    });
}


