import {  } from '@/api/order';
export default {
    namespaced: true,
    state: {
        orderStatus: '', // 订单状态

    },
    mutations: {
        /**
         * @description 订单号搜索
         */
        getOrderStatus (state, orderStatus) {
            state.orderStatus = orderStatus;
        },


    },
    actions: {

    }
};