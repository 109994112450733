import card from '@/components/card' // 个人中心 卡片
import cateNav from '@/components/nav/CateNav'
import drawer from './drawer/Main' // 右侧bar

drawer.install = function (Vue) {
    Vue.component('drawer', drawer);
};

card.install = function (Vue) {
    Vue.component('card', card)
}

cateNav.install = function (Vue) {
    Vue.component('cateNav', cateNav)
}


// 引用本js中所有的组件
export function InstallAll (Vue) {
    Vue.use(card)
    Vue.use(cateNav)
    Vue.use(drawer)
}
