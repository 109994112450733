/**
 * 首頁信息
 * */
import axios from 'axios';
import { adbannerList,specialsList,shopsList,brandList,cateList } from '@/api/index';
import { seckill_list } from '@/api/user';
export default {
    namespaced: true,
    state: {
        isLoading: false, // 是否展示loading动画
        orderBy: 'sale', // 根据什么字段排序
        userInfo: { // 用户信息
            username: ''
        },
        signUpStep: 0, // 登陆步骤
        marketing: [],
        seckills: { // 秒杀

        },
        specials: [{
            goods_thumb:''
        }],
        shopsList:{},
        eat: {}, // 爱吃专栏
        asItems: [], // 广告
        goodsList: [], // 商品列表
        shoppingCart: [], // 购物车
        newShoppingCart: [], // 刚加入的购物车，作为展示
        recommend: [], // 推荐购买
        brandList:[],
        cateList:[]
    },
    mutations: {
        // 设置轮播(营销)图
        SET_CAROUSELITEMS_INFO (state, data){
            state.marketing = data;
        },
        SET_SPECIAL_INFO(state, data){
            state.specials = data;
        },
        SET_SHOPS_INFO(state, data){
            state.shopsList = data;
        },
        SET_BRAND_LIST(state, data){
            state.brandList = data;
        },
        SET_CATE_LIST(state, data){
            state.cateList = data;
        },
        SET_SECKILL_LIST(state, data){
            state.seckills = data;
        },
    },
    actions: {
        // 获取轮播(营销)图片
        loadCarouselItems ({ commit }){
            return new Promise((resolve, reject) => {
                adbannerList().then(async res => {
                    commit('SET_CAROUSELITEMS_INFO', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        //天天特价
        loadSpecials ({ commit }){
            return new Promise((resolve, reject) => {
                specialsList({
                    page:1,
                    limit:5
                }).then(async res => {
                    commit('SET_SPECIAL_INFO', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShops ({ commit }){
            return new Promise((resolve, reject) => {
                shopsList({
                    page:1,
                    limit:5
                }).then(async res => {
                    commit('SET_SHOPS_INFO', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadBrands ({ commit }){
            return new Promise((resolve, reject) => {
                brandList({
                    page:1,
                    limit:5
                }).then(async res => {
                    commit('SET_BRAND_LIST', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadCateList ({ commit },{
            parent_id =''
        }){
            return new Promise((resolve, reject) => {
                cateList({
                    parent_id
                }).then(async res => {
                    commit('SET_CATE_LIST', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadSeckillList ({ commit }){
            return new Promise((resolve, reject) => {
                seckill_list({
                    page:1,
                    limit:10,
                    supplier_id:0
                }).then(async res => {
                    // console.log(res)
                    commit('SET_SECKILL_LIST', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
    },
    getters: {
        // 字段排序函数
        compare(property) {
            return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            };
        },

// 获取排序后的列表
        orderGoodsList(state) {
            return state.goodsList.sort(this.compare(state.orderBy));
        },

// 获取秒杀的小时
        seckillsHours(state) {
            return state.seckills.deadline.hours < 10 ? '0' + state.seckills.deadline.hours : state.seckills.deadline.hours;
        },

// 获取秒杀的分钟
        seckillsMinutes(state) {
            return state.seckills.deadline.minute < 10 ? '0' + state.seckills.deadline.minute : state.seckills.deadline.minute;
        },

// 获取秒杀的秒
        seckillsSeconds(state) {
            return state.seckills.deadline.seconds < 10 ? `0${state.seckills.deadline.seconds}` : state.seckills.deadline.seconds;
        }
    }
}
