import request from '@/plugins/request';
// import qs  from 'qs';

export function getOrderBonus(data) {
    return request({
        url: 'users/order/get_order_bonus',
        method: 'post',
        data
    });
}

//获取下单可用红包(立即购买)
export function get_order_bonus_now(data) {
    return request({
        url: 'users/order/get_order_bonus_now',
        method: 'post',
        data
    });
}

export function getCartOrder(data) {
    return request({
        url: 'users/order/cart_order',
        method: 'post',
        data
    });
}

//下单
export function submitOrder(data) {
    return request({
        url: 'users/order/sub_order',
        method: 'post',
        data
    });
}

//支付
export function submitPay(data) {
    // var data1 = qs.stringify(data);
    return request({
        url: 'epay/api/submit',
        method: 'post',
        // headers:{'Content-Type':'application/x-www-form-urlencoded'},
        // data:data1
        data
    });
}

//通过订单号获取支付途径
export function getPayCode(data) {
    return request({
        url: 'users/order/get_pay_code',
        method: 'post',
        data
    });
}


//立即购买
export function buy_now(data) {
    return request({
        url: 'users/order/buy_now',
        method: 'post',
        data
    });
}

//结算页面 (立即购买多规格)
export function buy_now_batch(data) {
    return request({
        url: 'users/order/buy_now_batch',
        method: 'post',
        data
    });
}

//提交订单 （多规格立即购买）
export function sub_order_now_bath(data) {
    return request({
        url: 'users/order/sub_order_now_bath',
        method: 'post',
        data
    });
}

