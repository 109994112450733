import request from '@/plugins/request';


export function walletInfo(data) {
    return request({
        url: 'users/wallet/index',
        method: 'post',
        data
    });
}

export function scoreList(data) {
    return request({
        url: 'users/wallet/score_list',
        method: 'post',
        data
    });
}

export function recharge(data) {
    return request({
        url: 'users/wallet/recharge',
        method: 'post',
        data
    });
}

export function rechargeLogList(data) {
    return request({
        url: 'users/wallet/recharge_log',
        method: 'post',
        data
    });
}

export function bonusList(data) {
    return request({
        url: 'users/personal/bonus_list',
        method: 'post',
        data
    });
}

export function bonusDelete(data) {
    return request({
        url: 'users/personal/bonus_del',
        method: 'post',
        data
    });
}

export function bonusInfo(data) {
    return request({
        url: 'users/personal/bonus_info',
        method: 'post',
        data
    });
}

export function account_log(data) {
    return request({
        url: 'users/wallet/account_log',
        method: 'post',
        data
    });
}

//用户提现申请
export function withdraw(data) {
    return request({
        url: 'users/wallet/withdraw',
        method: 'post',
        data
    });
}