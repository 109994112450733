import request from '@/plugins/request';

/**
 * @description 用户信息
 * @param {Object} param data {Object} 传值参数
 */
export function userInfo(data) {
    return request({
        url: 'users/personal/user_info',
        method: 'post',
        data
    });
}

export function userAddressList(data) {
    return request({
        url: 'users/personal/address_list',
        method: 'post',
        data
    });
}

export function addUserAddress(data) {
    return request({
        url: 'users/personal/address_edit_add',
        method: 'post',
        data
    });
}

export function delUserAddress(data) {
    return request({
        url: 'users/personal/address_del',
        method: 'post',
        data
    });
}

export function orderList(data) {
    return request({
        url: 'users/personal/order_list',
        method: 'post',
        data
    });
}

export function cancelOrder(data) {
    return request({
        url: 'users/personal/cancel_order',
        method: 'post',
        data
    });
}

//申请退款（订单待发货，待收货时使用）
export function refund_order(data) {
    return request({
        url: 'users/personal/refund_order',
        method: 'post',
        data
    });
}

//订单详情
export function orderInfo(data) {
    return request({
        url: 'users/personal/order_info',
        method: 'post',
        data
    });
}

//修改个人信息
export function saveInfo(data) {
    return request({
        url: 'users/personal/save_info',
        method: 'post',
        data
    });
}

export function updateInfo(data) {
    return request({
        url: 'users/personal/update_info',
        method: 'post',
        data
    });
}

export function repairList(data) {
    return request({
        url: 'users/personal/repair_list',
        method: 'post',
        data
    });
}

export function repairInfo(data) {
    return request({
        url: 'users/personal/repair_info',
        method: 'post',
        data
    });
}

export function collectGoodsList(data) {
    return request({
        url: 'users/personal/collect_goods',
        method: 'post',
        data
    });
}

export function collectShopList(data) {
    return request({
        url: 'users/personal/collect_shop',
        method: 'post',
        data
    });
}

export function delCollectGoods(data) {
    return request({
        url: 'users/personal/del_collect_goods',
        method: 'post',
        data
    });
}

export function delCollectShop(data) {
    return request({
        url: 'users/personal/del_collect_shop',
        method: 'post',
        data
    });
}

export function create_qrcode(data) {
    return request({
        url: 'users/personal/create_qrcode',
        method: 'post',
        data
    });
}

//提交维修订单
export function add_repair_order(data) {
    return request({
        url: 'users/personal/add_repair_order',
        method: 'post',
        data
    });
}

//确认收货订单
export function confirm_received(data) {
    return request({
        url: 'users/personal/confirm_received',
        method: 'post',
        data
    });
}

//意见反馈
export function feedback(data) {
    return request({
        url: 'users/personal/feedback',
        method: 'post',
        data
    });
}

//申请入驻成为商家
export function settled(data) {
    return request({
        url: 'users/personal/settled',
        method: 'post',
        data
    });
}

export function settled_info(data) {
    return request({
        url: 'users/personal/settled_info',
        method: 'post',
        data
    });
}

//账号信息
export function payment_info(data) {
    return request({
        url: 'users/personal/payment_info',
        method: 'post',
        data
    });
}

//添加修改账号
export function payment_edit(data) {
    return request({
        url: 'users/personal/payment_edit',
        method: 'post',
        data
    });
}

//删除账户
export function payment_delete(data) {
    return request({
        url: 'users/personal/payment_delete',
        method: 'post',
        data
    });
}

//获取优惠活动列表
export function favour_list(data) {
    return request({
        url: 'users/Favourable/get_list',
        method: 'post',
        data
    });
}


//获取拍卖活动列表
export function auction_list(data) {
    return request({
        url: 'users/auction/get_list',
        method: 'post',
        data
    });
}

//获取团购活动列表
export function groupbuy_list(data) {
    return request({
        url: 'users/groupbuy/get_list',
        method: 'post',
        data
    });
}

//获取预售活动列表
export function presale_list(data) {
    return request({
        url: 'users/presale/get_list',
        method: 'post',
        data
    });
}

//获取秒杀活动列表
export function seckill_list(data) {
    return request({
        url: 'users/seckill/get_list',
        method: 'post',
        data
    });
}

export function addScanLog(data) {
    return request({
        url: 'users/personal/add_scan_log',
        method: 'post',
        data
    });
}

export function remind(data) {
    return request({
        url: 'users/personal/remind',
        method: 'post',
        data
    });
}

export function delScanLog(data) {
    return request({
        url: 'users/personal/scan_log_del',
        method: 'post',
        data
    });
}

export function getScanLog(data) {
    return request({
        url: 'users/personal/scan_log_list',
        method: 'post',
        data
    });
}

export function add_collect_goods(data) {
    return request({
        url: 'users/personal/add_collect_goods',
        method: 'post',
        data
    });
}

export function order_total(data) {
    return request({
        url: 'users/personal/order_total',
        method: 'post',
        data
    });
}

//微店申请入驻市场
export function apply_market(data) {
    return request({
        url: 'users/personal/apply_market',
        method: 'post',
        data
    });
}


export function del_order(data) {
    return request({
        url: 'users/personal/del_order',
        method: 'post',
        data
    });
}

export function users_goods_list(data){
    return request({
        url: '/users/personal/users_goods_list',
        method: 'post',
        data
    })
}

export function users_goods_sale(data){
    return request({
        url: '/users/personal/users_goods_sale',
        method: 'post',
        data
    })
}

export function users_goods_del(data){
    return request({
        url: '/users/personal/users_goods_del',
        method: 'post',
        data
    })
}

export function add_users_goods(data){
    return request({
        url: '/users/personal/add_users_goods',
        method: 'post',
        data
    })
}

export function notice_list(data){
    return request({
        url: '/users/notice/notice_list',
        method: 'post',
        data
    })
}

export function notice_info(data){
    return request({
        url: '/users/notice/notice_info',
        method: 'post',
        data
    })
}

export function unread_total(data){
    return request({
        url: '/users/personal/unread_total',
        method: 'post',
        data
    })
}




