/**
 * 钱包信息
 * */
import { walletInfo} from '@/api/wallet';
import util from "@/libs/util";
export default {
    namespaced: true,
    state: {
        walletInfo: {},
        total:0
    },
    mutations: {
        SET_WALLET_INFO (state, data){
            state.walletInfo = data;
            state.total = parseFloat(data.user_money) + parseFloat(data.frozen_money);
        }
    },
    actions: {
        loadWalletInfo({ commit }) {
            return new Promise((resolve, reject) => {
                // 开始请求登录接口
                const app_token = util.cookies.get('app_token');
                walletInfo({
                    app_token
                })
                    .then(async res => {
                        commit('SET_WALLET_INFO', res.data);
                        // 结束
                        resolve(res);
                    })
                    .catch(err => {
                        reject(err);
                    })
            })
        },
    }
}
