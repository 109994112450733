<template>
    <div class="box">
        <div class="nav">
<!--            <div class="welcome"></div>-->
<!--            <ul class="location">-->
<!--                <li>-->
<!--                              <Dropdown  placement="bottom-start">-->
<!--                                <a href="javascript:void(0)">-->
<!--                                  <Icon type="ios-locate" class="icon" /> {{city}}-->
<!--                                </a>-->
<!--                                <DropdownMenu slot="list">-->
<!--                                  <div class="city">-->
<!--                                    <p v-for="(items, index) in cityArr" :key="index">-->
<!--                                      <span v-for="(item, index) in items"  class="city-item" :key="index" @click="changeCity(item)">{{item}}</span>-->
<!--                                    </p>-->
<!--                                  </div>-->
<!--                                </DropdownMenu>-->
<!--                              </Dropdown>-->
<!--                </li>-->
<!--            </ul>-->
            <ul class="site-nav-bd-l">
                <li v-show="!userInfo.app_token">
                    请
                    <router-link to="/login">登录
                        <Icon type="md-person"></Icon>
                    </router-link>
                    |<span class="text-color-red"><router-link to="/SignUp">注册 <Icon
                        type="md-person-add"></Icon></router-link></span>
                </li>
                <li v-show="!!userInfo.app_token">
                    <Dropdown>
                        <div class="username-p">
                            <!--                            <Avatar class="person-icon" :src="userInfo.headimg" icon="ios-person" size="small"/>-->
                            <span class="username">{{userInfo.user_name}} </span><Icon type="ios-arrow-down" />
                        </div>
                        <DropdownMenu slot="list">
                            <div class="my-page">
                                <div class="my-info" @click="myInfo">
                                    <Icon type="md-home"></Icon>
                                    <p>我的主页</p>
                                </div>
<!--                                <div class="my-info" @click="myCart">-->
<!--                                    <Icon type="md-cart"></Icon>-->
<!--                                    <p>购物车</p>-->
<!--                                </div>-->
                                <div class="sign-out" @click="signOutFun">
                                    <Icon type="md-log-out"></Icon>
                                    <p>退出登录</p>
                                </div>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </li>
            </ul>
            <ul class="site-nav-bd-r">

                <!--        <li>-->
                <!--          <Dropdown  placement="bottom-start">-->
                <!--            <a href="javascript:void(0)">-->
                <!--              <Icon type="ios-cart-outline"></Icon> 购物车-->
                <!--            </a>-->
                <!--            <DropdownMenu slot="list">-->
                <!--              <div class="shopping-cart-null" v-show="shoppingCart.length <= 0">-->
                <!--                <Icon type="ios-cart-outline" class="cart-null-icon"></Icon>-->
                <!--                <span>你的购物车没有空空哦</span>-->
                <!--                <span>赶快去添加商品吧~</span>-->
                <!--              </div>-->
                <!--              <div class="shopping-cart-list" v-show="shoppingCart.length > 0">-->
                <!--                <div class="shopping-cart-box" v-for="(item,index) in shoppingCart" :key="index">-->
                <!--                  <div class="shopping-cart-img">-->
                <!--                    <img :src="item.img">-->
                <!--                  </div>-->
                <!--                  <div class="shopping-cart-info">-->
                <!--                    <div class="shopping-cart-title">-->
                <!--                      <p>{{item.title.substring(0, 22)}}...</p>-->
                <!--                    </div>-->
                <!--                    <div class="shopping-cart-detail">-->
                <!--                      <p>-->
                <!--                        套餐:-->
                <!--                        <span class="shopping-cart-text">-->
                <!--                          {{item.package}}-->
                <!--                        </span>-->
                <!--                        数量:-->
                <!--                        <span class="shopping-cart-text">-->
                <!--                          {{item.count}}-->
                <!--                        </span>-->
                <!--                        价钱:-->
                <!--                        <span class="shopping-cart-text">-->
                <!--                          {{item.price}}-->
                <!--                        </span>-->
                <!--                      </p>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <div class="go-to-buy">-->
                <!--                  <Button type="error" size="small" @click="goToPay">-->
                <!--                    去结账-->
                <!--                  </Button>-->
                <!--                </div>-->
                <!--              </div>-->
                <!--            </DropdownMenu>-->
                <!--          </Dropdown>-->
                <!--        </li>-->
                <!--        <li><router-link to="/">网站导航</router-link></li>-->
                <li class="site-nav-menu">
                    <router-link to="/cart">
                        购物车
                    </router-link>
                </li>
                <li class="site-nav-menu">
                    <router-link to="/my/myOrder">
                        我的订单
                    </router-link>
                </li>
<!--                <li class="site-nav-menu">-->
<!--                    <router-link to="/feedBack">意见反馈</router-link>-->
<!--                </li>-->
                <li class="site-nav-menu">
                    <router-link to="/my/msgList">我的消息<span class="badge" v-show="!!userInfo.app_token && msgCount > 0">{{msgCount}}</span></router-link>
                </li>
                <li class="site-nav-menu">
                    <router-link to="/helpCenter">帮助中心</router-link>
                </li>
                <li class="site-nav-menu">
                    <router-link to="/merchantsettled">商家入驻</router-link>
                </li>
                <li class="site-nav-menu">
                    <router-link to="/" style="border-right: transparent;">商城首页</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';
    import {unread_total} from "@/api/user";
    import util from "@/libs/util";
    export default {
        name: 'M-Header',
        data() {
            return {
                city: '南宁',
                cityArr: [
                    ['北京', '上海', '天津', '重庆', '广州'],
                    ['深圳', '河南', '辽宁', '吉林', '江苏'],
                    ['江西', '四川', '海南', '贵州', '云南'],
                    ['西藏', '陕西', '甘肃', '青海', '珠海']
                ],
                msgCount:0
            };
        },
        computed: {
            ...mapState('front/user', ['userInfo','isLogin']),
            ...mapState('front/layout', [
                'logoutConfirm'
            ])
        },
        created() {
            if(this.isLogin){
                unread_total({
                   app_token:util.cookies.get('app_token')
                }).then(res=>{
                    this.msgCount = parseInt(res.data.num);
                })
            }

        },
        methods: {
            ...mapActions('front/account', [
                'logout'
            ]),
            changeCity(city) {
                this.city = city;
            },
            goToPay() {
                this.$router.push('/order');
            },
            myInfo() {
                this.$router.push('/my');
            },
            myCart() {
                this.$router.push('/cart');
            },
            signOutFun() {
                this.logout({
                    confirm: this.logoutConfirm,
                    vm: this
                });
            }
        }
    };
</script>

<style scoped lang="scss">
    .shopping-cart-detail,
    .shopping-cart-text,
    .shopping-cart-info,
    .nav a,
    .location,
    .first,
    .username,
    .shopping-cart-null span {

    }
    /deep/ .ivu-select-dropdown{
        z-index: 99;
    }

    .box {
        width: 100%;
        font-size: 12px !important;
        height: 35px;
        z-index: 99;
        @include background_color($light_background_color);
    }

    .nav {
        background-color: #F1F1F1;
        margin: 0 auto;
        width: 1200px;
        height: 35px;
    }

    .nav ul {
        list-style: none;
    }
    .nav .site-nav-bd-l{
        float: left;
    }
    .nav .site-nav-bd-r{
        float: right;
    }
    .nav .site-nav-bd-r .site-nav-menu{
        float: left;
    }

    .nav ul li {
        font-size: 12px;
        line-height: 35px;
        margin-right: 10px;
        font-weight: bold;
    }

    .site-nav-menu a{
        text-decoration: none!important;
        padding-right: 14px;
        border-right: 1px solid #ccc;
        color: #6C6C6C;
        cursor: pointer;
    }

    .icon {
        color: gray;
        vertical-align: middle;
    }



    .city {
        padding: 10px 15px;
    }

    .city-item {
        font-weight: bold;
        cursor: pointer;
        padding: 5px;
    }

    .city-item:hover {
        color: $theme_color;
    }

    .person-icon {
        color: $theme_color;
        background-color: #f0cdb2;
    }

    .shopping-cart-list {
        padding: 10px 15px;
        box-sizing: border-box;
        max-height: 300px;
        overflow: scroll;
    }

    .shopping-cart-box {
        margin: 8px 0px;
        margin-top: 15px;
        padding-bottom: 15px;
        height: 40px;
        display: flex;
        align-items: center;
        border-bottom: 1px #ccc dotted;
    }

    .shopping-cart-box:first-child {
        margin-top: 8px;
    }

    .shopping-cart-img {
        margin-right: 15px;
        width: 40px;
        height: 40px;
    }

    .shopping-cart-img img {
        width: 100%;
    }

    .shopping-cart-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-content: space-between;
        width: 200px;
        overflow: hidden;
        font-size: 12px;
        line-height: 20px;
    }

    .go-to-buy {
        display: flex;
        justify-content: flex-end;
    }

    .shopping-cart-null {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .cart-null-icon {
        font-size: 38px;
        margin-bottom: 15px;
    }

    .shopping-cart-null span {
        font-size: 12px;
        line-height: 16px;
    }

    .username-p {
        position: relative;
      cursor: pointer;
        .username{
          margin-left: 5px;
        }
        div {
            cursor: pointer;

            > span {
                margin-left: 5px;
            }
        }

        .drop-items {
            position: absolute;
            display: none;
            top: 45px;
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 5px 10px;
            z-index: 20;
            height: 150px;
            background-color: #fff;
            width: 80px;
            border: 1px solid #eee;
            box-shadow: 2px 2px 7px #999;

            li {
                color: rgb(107, 106, 106);
                width: 100%;
                border-bottom: 1px solid rgb(207, 206, 206);
                font-weight: normal;
                text-align: center;

                &:last-child {
                    border: none;
                }

                &:hover {
                    cursor: pointer;
                    color: $theme_color;
                }
            }

            &::before {
                position: absolute;
                top: -20px;
                left: 30px;
                content: '';
                width: 0;
                height: 0;
                border: 10px solid #999;
                border-color: transparent transparent #fff transparent;
            }

            &::after {
                content: '';
                position: absolute;
                width: 80px;
                height: 20px;
                top: -20px;
                left: 0;
            }
        }

        &:hover {
            .drop-items {
                display: block;
            }
        }
    }

    .my-page {
        padding: 3px 5px;
        width: 250px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .my-page a {
        margin: 0px;
        padding: 0px;
        border: none;
    }

    .my-info {
        padding: 5px;
        width: 50%;
        height: 100%;
        text-align: center;
        cursor: pointer;
    }

    .my-info:hover {
        box-shadow: 0px 0px 5px #ccc;
    }

    .my-info i {
        font-size: 28px;
    }

    .my-info p {
        font-size: 12px;
    }

    .sign-out {
        padding: 5px;
        width: 50%;
        height: 100%;
        text-align: center;
        cursor: pointer;
    }

    .sign-out:hover {
        box-shadow: 0px 0px 5px $border_color;
    }

    .sign-out i {
        font-size: 28px;
    }

    .sign-out p {
        font-size: 12px;
    }

    .goods-title:hover {
        color: $theme_color;
    }

    .cart-badge {
        position: absolute;
        right: -8px;
        font-style: normal;
        background-color: $theme_color;
        color: #fff;
        font-size: 12px;
        width: 17px;
        height: 17px;
        border-radius: 10px;
        line-height: 17px;
        text-align: center;
        z-index: 3;
        top: 3px;
    }
    .badge{
        font-family: "Monospaced Number";
        transform: translateX(30%);
        height: 20px;
        border-radius: 10px;
        min-width: 20px;
        background: #ed4014;
        border: 1px solid transparent;
        color: #fff;
        line-height: 18px;
        text-align: center;
        display: inline-block;
    }
</style>
