/*
 *广告链接请求接口
 */

import axios from 'axios';
import {Message} from "view-design";
import util from "@/libs/util";
import router from "@/router";
import {showFullScreenLoading, tryHideFullScreenLoading} from "@/plugins/request";


const service = axios.create({
    baseURL: 'http://admin.ylscw.net/',
    timeout: 10000, // 请求超时时间
    // crossDomain: true
});
// axios.defaults.withCredentials = false;
// 请求拦截器
service.interceptors.request.use(
    async config => {
        // 开启loading
        showFullScreenLoading();
        return config;
    },
    error => {
        // 发送失败
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 获取服务器类型
        if (!sessionStorage.getItem("SERVER_TYPE")) {
            sessionStorage.setItem("SERVER_TYPE", response.headers['server']);
        }
        // 关闭loading
        tryHideFullScreenLoading();
        const code = response.data.status;
        switch (code) {
            case -2:
                Message.warning('登录异常，请重新登录');
                setTimeout(()=>{
                    localStorage.clear();
                    sessionStorage.clear()
                    util.cookies.remove('app_token')
                    util.cookies.remove('user_id');
                    // 跳转路由
                    router.push({
                        name: 'Login'
                    });
                },2000)
                break;
            case -1:
                return response.data;
            case 0:
                // [ 示例 ] code === 0 代表没有错误
                return response.data;
            case 1:
                // [ 示例 ] code === 0 代表没有错误
                return response.data;
            case 400:
            case 400011:
            case 400012:
                return Promise.reject(response.data || {msg: '未知错误'});
            // [ 示例 ] 其它和后台约定的 code
            // errorCreate(response.data.msg);
            // break;
            case 410000:
            case 410001:
            case 410002:
                window.router.replace('/front/login');
                break;
            case 410003:

                break;
            default:
                // 不是正确的 code
                // errorCreate(`${dataAxios.msg}: ${response.config.url}`);
                break;
        }
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    error.message = '请求错误';
                    break;
                case 401:
                    error.message = '未授权，请登录';
                    break;
                case 403:
                    error.message = '拒绝访问';
                    break;
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    error.message = '请求超时';
                    break;
                case 500:
                    error.message = '服务器内部错误';
                    break;
                case 501:
                    error.message = '服务未实现';
                    break;
                case 502:
                    error.message = '网关错误';
                    break;
                case 503:
                    error.message = '服务不可用';
                    break;
                case 504:
                    error.message = '网关超时';
                    break;
                case 505:
                    error.message = 'HTTP版本不受支持';
                    break;
                default:
                    break;
            }
        }
        // errorLog(error);
        // return Promise.reject(error);
    }
);

export default service;
