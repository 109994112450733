<template>
    <Card class="_Card" style="width:890px;border: 2px solid #E5E6E9;height: 120px;">
        <div slot="title" style="display: flex;background-color: #F3F4F8">
            <img v-lazy="userInfo.headimg" style="width: 40px;height: 40px;align-self: center;margin-left: 10px;">
            <div style="margin-left: 10px;">
                <div style="line-height: 30px;">{{userInfo.user_name}}</div>
                <div style="line-height: 30px;">{{userInfo.mobile_phone}}</div>
            </div>
        </div>
        <div slot="extra">
            <router-link to="/account/myAddress">
                我的收货地址
            </router-link>
<!--            <router-link to="/my/AddAddress">-->
<!--                我的收货地址-->
<!--            </router-link>-->
<!--            <a href="#" @click.prevent="myAddress" class="my">-->
<!--                我的优惠信息-->
<!--            </a>-->
        </div>
        <div class="card_body">
            <ul>
                <li class="item hover-pointer" @click="tabsChange(1)">
                    <img src="@/assets/images/currency.png">
                    待付款
                    <div class="num">{{_Status.wait_pay}}</div>
                </li>
                <li>
                    <span>|</span>
                </li>
                <li class="item hover-pointer" @click="tabsChange(3)">
                    <img src="@/assets/images/wait.png">
                    待发货
                    <div class="num">{{_Status.wait_send}}</div>
                </li>
                <li>
                    <span>|</span>
                </li>
                <li class="item hover-pointer" @click="tabsChange(4)">
                    <img src="@/assets/images/wait_truck.png">
                    待收货
                    <div class="num">{{_Status.wait_recieve}}</div>
                </li>
                <li>
                    <span>|</span>
                </li>
                <li class="item hover-pointer" @click="tabsChange(5)">
                    <img src="@/assets/images/flower.png">
                    已完成
                    <div class="num">{{_Status.finished}}</div>
                </li>
<!--                <li>-->
<!--                    <span>|</span>-->
<!--                </li>-->
<!--                <li class="item">-->
<!--                    <img src="@/assets/images/refund.png">-->
<!--                    <router-link to="/">退款</router-link>-->
<!--                    <div class="num">暂无</div>-->
<!--                </li>-->
            </ul>
        </div>
    </Card>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'index',
        props:
            {
                _Tabs: { // 可点击的tab栏
                    type: null,
                    default: ''
                },
                // 头部
                _Title: { // 标题
                    type: null,
                    default: '卡片头部'
                },
                // 右侧更多
                _More: {
                    type: null,
                    default: false
                },
                _Size: { // 文字大小
                    type: Number,
                    default: 16
                },
                // 点击更多触发跳转
                _Src: {
                    type: null,
                    default: function (val) {
                        if (this._More) {
                            return val;
                        } else {
                            return false;
                        }
                    }
                },
                _Status:{
                    type:Object,
                    default:null
                }
            },
        data() {
            return {};
        },
        computed: {
            ...mapState('front/user', ['userInfo']),
        },
        methods: {
            tabsChange (index) {
                this.$emit('_Change', index);
            }
        }
    };
</script>

<style scoped lang="scss">
    /deep/ .ivu-card, .ivu-card-head, ._Card {
        margin-bottom: 20px;
        @include white_background_color();
    }

    /deep/ .ivu-card-head {
        padding: 0 !important;
    }

    /deep/ .ivu-card-body {
        padding: 0 !important;
    }
    /deep/ .ivu-card-extra{
        top:20% !important;
        right: 10%!important;
        .my{
            color: #333;
            margin-left: 50px;
        }
        .my:hover{
            color: $primary_color;
        }
    }
    .card_body{
        /*width: 890px;*/
    }
    .card_body ul{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        list-style: none;
        .item{
            float: left;
            width: 160px;
            img{
                margin-left: 35px;
                vertical-align:text-bottom;
                display: inline-block;
            }
            a{
                color: #333333;
                padding: 0 10px;
                display: inline-block;
            }
            .num{
                display: inline-block;
                color: $primary_color;
            }
        }
         span {
            font-family: "微软雅黑";
            font-size: 14px;
            color: #E9E9E9;
            float: left;
        }
    }

</style>
