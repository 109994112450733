/**
 * 店铺街信息
 * */
import { streetList,typeList,shopDesc,shopHeaderInfo,shopPhotos,shopNews,shopRepair,shopPickup,newsDetail,shopBanner,shopGoodsCat,shopService } from '@/api/shops';
export default {
    namespaced: true,
    state: {
        shopsList:{},
        shopListTotal:0, //页面总数
        typeList:{},
        shopDesc:{
            shop_logo:''
        },
        shopHeaderInfo:{},
        shopPhotos:{},
        shopNews:{},
        shopRepair:[],
        shopPickup:{},
        newsDetail:'',
        shopBanner:{},
        shopGoodsCat:{},
        repairService:{}
    },
    mutations: {
        SET_SHOPS_LISTS(state, data){
            state.shopsList = data;
        },
        SET_SHOPS_LISTS_TOTAL(state, data){
            state.shopListTotal = data;
        },
        SET_TYPE_LISTS(state, data){
            state.typeList = data;
        },
        SET_SHOPS_DESC(state, data){
            state.shopDesc = data;
        },
        SET_SHOPS_HEADER_INFO(state, data){
            state.shopHeaderInfo = data;
        },
        SET_SHOP_PHOTOS(state, data){
            state.shopPhotos = data;
        },
        SET_SHOP_NEWS(state, data){
            state.shopNews = data;
        },
        SET_SHOP_REPAIR(state, data){
            state.shopRepair = data;
        },
        SET_SHOP_PICKUP(state, data){
            state.shopPickup = data;
        },
        SET_NEWS_DETAIL(state, data){
            state.newsDetail = data;
        },
        SET_SHOP_BANNER(state, data){
            state.shopBanner = data;
        },
        SET_SHOP_GOODS_CAT(state, data){
            state.shopGoodsCat = data;
        },
        SET_SHOP_SERVICE(state, data){
            state.repairService = data;
        },
    },
    actions: {
        loadShopsList ({ commit },data){
            return new Promise((resolve, reject) => {
                streetList(data).then(async res => {
                    commit('SET_SHOPS_LISTS', res.data);
                    commit('SET_SHOPS_LISTS_TOTAL', res.total);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadTpyeList ({ commit }){
            return new Promise((resolve, reject) => {
                typeList().then(async res => {
                    commit('SET_TYPE_LISTS', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopDesc ({ commit },data){
            return new Promise((resolve, reject) => {
                shopDesc(data).then(async res => {
                    commit('SET_SHOPS_DESC', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopHeaderInfo ({ commit },data){
            return new Promise((resolve, reject) => {
                shopHeaderInfo(data).then(async res => {
                    commit('SET_SHOPS_HEADER_INFO', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopPhotos ({ commit },data){
            return new Promise((resolve, reject) => {
                shopPhotos(data).then(async res => {
                    commit('SET_SHOP_PHOTOS', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopNews ({ commit },data){
            return new Promise((resolve, reject) => {
                shopNews(data).then(async res => {
                    commit('SET_SHOP_NEWS', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopRepair ({ commit },data){
            return new Promise((resolve, reject) => {
                shopRepair(data).then(async res => {
                    commit('SET_SHOP_REPAIR', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopPickup ({ commit },data){
            return new Promise((resolve, reject) => {
                shopPickup(data).then(async res => {
                    commit('SET_SHOP_PICKUP', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadNewsDetail ({ commit },data){
            return new Promise((resolve, reject) => {
                newsDetail(data).then(async res => {
                    commit('SET_NEWS_DETAIL', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopBanner ({ commit },data){
            return new Promise((resolve, reject) => {
                shopBanner(data).then(async res => {
                    commit('SET_SHOP_BANNER', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopGoodsCat ({ commit },data){
            return new Promise((resolve, reject) => {
                shopGoodsCat(data).then(async res => {
                    commit('SET_SHOP_GOODS_CAT', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
        loadShopService({ commit },data){
            return new Promise((resolve, reject) => {
                shopService(data).then(async res => {
                    commit('SET_SHOP_SERVICE', res.data);
                    resolve(res);
                }).catch(res => {
                    reject(res);
                })
            });
        },
    },
    getters: {

    }
}
