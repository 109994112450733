import adRequest from '@/plugins/adRequest';

export function help_center (data) {
    return adRequest({
        url: 'api/article/help_center',
        method: 'post',
        data
    });
}

export function footer_guide (data) {
    return adRequest({
        url: 'api/article/footer_guide',
        method: 'post',
        data
    });
}
