import Vue from 'vue'
import VueRouter from 'vue-router'
import util from '@/libs/util'

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login')
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: () => import('@/views/ForgetPassword')
  },
  {
    path: '/SignUp', // 注册
    name:'SignUp',
    component: () => import('@/views/SignUp'),
  },
  {
    path: '/order', // 订单页面
    name: 'Order',
    component: () => import('@/views/Order'),
  },
  {
    path: '/minOrder', // 微店订单页面
    name: 'MinOrder',
    component: () => import('@/views/MinOrder'),
  },
  {
    path: '/orderBuyNow', // 订单页面(立即购买)
    name: 'OrderBuyNow',
    component: () => import('@/views/OrderBuyNow'),
  },
  {
    path: '/pay', // 支付页面
    name: 'Pay',
    component: () => import('@/views/Pay'),
  },
  {
    path: '/minpay', // 支付页面
    name: 'Minpay',
    component: () => import('@/views/Minpay'),
  },
  {
    path: '/payDone', // 支付成功页面
    name: 'PayDone',
    component: () => import('@/views/PayDone'),
  },
  {
    path: '/feedBack', // 反馈页面
    name: 'FeedBack',
    component: () => import('@/views/FeedBack'),
  },
  {
    path: '/helpCenter', // 反馈页面
    name: 'HelpCenter',
    component: () => import('@/views/HelpCenter'),
  },
  {
    path:'/account',
    component:()=>import('@/views/AccountCenter'),
    children: [
      {
        path: 'securityConfig',
        name: 'SecurityConfig',
        component: () => import('@/views/account/SecurityConfig')
      },
      {
        path: 'myAddress',
        name: 'MyAddress',
        component: () => import('@/views/account/MyAddress')
      },
      {
        path: 'myProfile',
        name: 'MyProfile',
        component: () => import('@/views/account/MyProfile')
      },
    ]
  },
  {
    path: '/activeList/favourList',
    name: 'FavourList',
    component: () => import('@/views/activeList/FavourList')
  },
  {
    path: '/activeList/hotList',
    name: 'HotList',
    component: () => import('@/views/activeList/HotList')
  },
  {
    path: '/activeList/seckillList',
    name: 'seckillList',
    component: () => import('@/views/activeList/SeckillList')
  },
  {
    path: '/my',
    component: () => import('@/views/MyCenter'),
    children: [
      {
        path: '/',
        name: 'HomeIndex',
        component: () => import('@/views/my/MyOrder')
      },
      {
        path: 'myAddress',
        name: 'MyAddress',
        component: () => import('@/views/my/MyAddress')
      },
      {
        path: 'addAddress',
        name: 'AddAddress',
        component: () => import('@/views/my/AddAddress')
      },
      {
        path: 'myOrder',
        name: 'MyOrder',
        component: () => import('@/views/my/MyOrder')
      },
      {
        path: 'msgList',
        name: 'MsgList',
        component: () => import('@/views/my/MsgList')
      },
      {
        path: 'myInvoice',
        name: 'MyInvoice',
        component: () => import('@/views/my/MyInvoice')
      },
      {
        path: 'myIntegral',
        name: 'MyIntegral',
        component: () => import('@/views/my/MyIntegral')
      },
      {
        path: 'myCoupon',
        name: 'MyCoupon',
        component: () => import('@/views/my/MyCoupon')
      },
      {
        path: 'orderDetail',
        name: 'OrderDetail',
        component: () => import('@/views/my/OrderDetail')
      },
      {
        path: 'myProfile',
        name: 'MyProfile',
        component: () => import('@/views/my/MyProfile')
      },
      {
        path: 'mySecurity',
        name: 'MySecurity',
        component: () => import('@/views/my/MySecurity')
      },
      {
        path: 'visitLog',
        name: 'VisitLog',
        component: () => import('@/views/my/VisitLog')
      },
      {
        path: 'myCollection',
        name: 'MyCollection',
        component: () => import('@/views/my/MyCollection')
      },
      {
        path:'walletIndex',
        name:'WalletIndex',
        component:() => import('@/views/my/wallet/WalletIndex')
      },
      {
        path:'walletRecharge',
        name:'WalletRecharge',
        component:() => import('@/views/my/wallet/WalletRecharge')
      },
      {
        path:'walletCashOut',
        name:'WalletCashOut',
        component:() => import('@/views/my/wallet/WalletCashOut')
      },
      {
        path:'integralExchange',
        name:'IntegralExchange',
        component:() => import('@/views/my/wallet/IntegralExchange')
      },
      {
        path: 'myBonus',
        name: 'MyBonus',
        component: () => import('@/views/my/wallet/MyBonus')
      },
      {
        path: 'accountList',
        name: 'AccountList',
        component: () => import('@/views/my/wallet/AccountList')
      },
      {
        path:'repairIndex',
        name:'RepairIndex',
        component:() => import('@/views/my/repair/RepairIndex')
      },
      {
        path:'addEval',
        name:'AddEval',
        component:() => import('@/views/my/evaluation/AddEval')
      },
      {
        path:'distributionIndex',
        name:'DistributionIndex',
        component:() => import('@/views/my/distribution/DistributionIndex')
      },
      // {
      //   path:'distributionOrder',
      //   name:'DistributionOrder',
      //   component:() => import('@/views/my/distribution/DistributionOrder')
      // },
      // {
      //   path:'distributionQrcode',
      //   name:'DistributionQrcode',
      //   component:() => import('@/views/my/distribution/DistributionQrcode')
      // },
      // {
      //   path:'distributionGoods',
      //   name:'DistributionGoods',
      //   component:() => import('@/views/my/distribution/DistributionGoods')
      // },
      // {
      //   path:'distributionBills',
      //   name:'DistributionBills',
      //   component:() => import('@/views/my/distribution/DistributionBills')
      // },
      // {
      //   path:'distributionStore',
      //   name:'DistributionStore',
      //   component:() => import('@/views/my/distribution/DistributionStore')
      // },
      // {
      //   path:'distributionCart',
      //   name:'DistributionCart',
      //   component:() => import('@/views/my/distribution/DistributionCart')
      // },
      {
        path: 'minShopOrderList',
        name: 'MinShopOrderList',
        component: () => import('@/views/minShops/MinShopOrderList')
      },
      {
        path: 'minShopOrderDetail',
        name: 'MinShopOrderDetail',
        component: () => import('@/views/minShops/MinShopOrderDetail')
      },
    ]
  },
  {
    path: '/shopStreet',
    name: 'ShopStreet',
    component: () => import('@/views/shops/ShopStreet'),
  },
  {
    path: '/brandStreet',
    name: 'BrandStreet',
    component: () => import('@/views/BrandStreet'),
  },
  {
    path: '/shopDetail',
    name: 'ShopDetail',
    component: () => import('@/views/shops/ShopDetail')
  },
  {
    path: '/shopCategoryList',
    name: 'ShopCategoryList',
    component: () => import('@/views/shops/ShopCategoryList')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/shops/Cart')
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: () => import('@/views/shops/NewsDetail')
  },
  {
    path: '/goodsList', // 商品列表
    name: 'GoodsList',
    component: () => import('@/views/GoodsList')
  },
  {
    path: '/goodsDetail', // 商品详情
    name: 'GoodsDetail',
    component: () => import('@/views/GoodsDetail')
  },
  {
    path: '/goodsDetailSeckill', // 商品详情
    name: 'GoodsDetailSeckill',
    component: () => import('@/views/GoodsDetailSeckill')
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('@/views/Category')
  },
  {
    path: '/categoryList',
    name: 'CategoryList',
    component: () => import('@/views/CategoryList')
  },
  {
    path: '/merchantSettled', // 注册
    name: 'MerchantSettled',
    component: () => import('@/views/MerchantSettled'),
    children: [
      {
        path: '/',
        name: 'index',
        component: () => import('@/views/merchant/CheckAgreement'),
      },
      {
        path: 'checkAgreement',
        name: 'CheckAgreement',
        component: () => import('@/views/merchant/CheckAgreement'),
      },
      {
        path: 'businessInfo',
        name: 'BusinessInfo',
        component: () => import('@/views/merchant/BusinessInfo'),
      },
      {
        path: 'storeInfo',
        name: 'StoreInfo',
        component: () => import('@/views/merchant/StoreInfo'),
      },
      {
        path:'auditState',
        name:'AuditState',
        component:() => import('@/views/merchant/AuditState')
      }
    ]
  },
  {
    path: '/minShopDetail',
    name: 'MinShopDetail',
    component: () => import('@/views/minShops/MinShopDetail')
  },
  {
    path: '/minShopList',
    name: 'MinShopList',
    component: () => import('@/views/minShops/MinShopList')
  },
  {
    path: '/minShopGoodsDetail',
    name: 'MinShopGoodsDetail',
    component: () => import('@/views/minShops/MinShopGoodsDetail')
  },
  {
    path: '/minShopGoodsList',
    name: 'MinShopGoodsList',
    component: () => import('@/views/minShops/MinShopGoodsList')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
    // 判断是否需要登录才可以进入
    // 这里依据 token 判断是否登录，可视情况修改
    const token = util.cookies.get('app_token');
  if (!token && token === 'undefined') {
    if (to.path === '/login' || to.path === '/') {
      next();
    } else {
      next({ path: '/login' })
    }
  } else {
    next();
  }
});

export default router
