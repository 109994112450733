import request from '@/plugins/request';

export function streetList (data) {
    return request({
        url: 'users/index/street_list',
        method: 'post',
        data
    });
}

export function typeList () {
    return request({
        url: 'users/index/street_type',
        method: 'post',
    });
}

export function shopDesc (data) {
    return request({
        url: 'users/shop/shop_desc',
        method: 'post',
        data
    });
}

export function shopHeaderInfo (data) {
    return request({
        url: 'users/shop/shop_info',
        method: 'post',
        data
    });
}

export function shopPhotos (data) {
    return request({
        url: 'users/shop/photo_list',
        method: 'post',
        data
    });
}

export function shopNews (data) {
    return request({
        url: 'users/shop/article_list',
        method: 'post',
        data
    });
}

export function shopRepair (data) {
    return request({
        url: 'users/shop/repair_point_list',
        method: 'post',
        data
    });
}

export function shopPickup(data) {
    return request({
        url: 'users/shop/picked_up_list',
        method: 'post',
        data
    });
}

export function newsDetail(data) {
    return request({
        url: 'users/shop/article_info',
        method: 'post',
        data
    });
}

export function shopBanner(data) {
    return request({
        url: 'users/shop/ad_list',
        method: 'post',
        data
    });
}

export function shopGoodsCat(data) {
    return request({
        url: 'users/shop/cate_list',
        method: 'post',
        data
    });
}

export function shopService(data) {
    return request({
        url: 'users/after_sale/repair_goods_list',
        method: 'post',
        data
    });
}

export function getGoodsList(data) {
    return request({
        url: 'users/shop/search_goods',
        method: 'post',
        data
    });
}

export function shopCollect(data) {
    return request({
        url: 'users/personal/add_collect_shop',
        method: 'post',
        data
    });
}

export function shopUnCollect(data) {
    return request({
        url: 'users/personal/del_collect_shopid',
        method: 'post',
        data
    });
}



export function getPayList(data) {
    return request({
        url: 'users/shop/pay_list',
        method: 'post',
        data
    });
}

